import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, uiHelper, serviceFactory } from 'cv-react-core';
import TextLabel from '../TextLabel';

import getStyles from './AppFooter.styles';

/**
 * The application footer
 */
const AppFooter = forwardRef((props, ref) => {
    const {
        contextStyles,
        testID,
    } = props;

    const {
        container,
        text,
        textContainer,
        versionContainer,
    } = getStyles(contextStyles);

    // Generate container props
    const containerProps = {
        style: container,
    };
    if (testID) { containerProps['test-id'] = testID; }

    // Generate text props
    const textProps = {
        contextStyles: {
            container: textContainer,
            text,
        },
    };
    const { sessionStore } = rootStore;
    const { lang } = serviceFactory;
    const { applicationInfo } = lang;
    const appInfo = uiHelper.getClientInformation(sessionStore);
    const versions = appInfo.filter((version) => version.name === applicationInfo.ClientReleaseVersion ||
        version.name === applicationInfo.DialogServerVersion || version.name === applicationInfo.CloudServerVersion);

    return (
        <div
            ref={ ref }
            { ...containerProps }>
            <div style={ versionContainer }>
                {
                    versions.map((version) => (
                        version.value &&
                        <TextLabel
                            key={ version.name }
                            { ...textProps }>
                            { `${version.name}: ${version.value}` }
                        </TextLabel>
                    ))
                }
            </div>
            <TextLabel { ...textProps }>
                Powered By Xalt
            </TextLabel>
        </div>
    );
});

AppFooter.propTypes = {
    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main container */
        container: PropTypes.object,

        /** Styles for text */
        text: PropTypes.object,

        /** Styles for the container surrounding the text */
        textContainer: PropTypes.object,

        /** Styles for the container surrounding versions */
        versionContainer: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,
};

AppFooter.defaultProps = {
    contextStyles: {},
};

AppFooter.displayName = 'AppFooter';

export default AppFooter;
